var expandInfoPositions = {
  SIDE: 'SIDE',
  BOTTOM: 'BOTTOM'
};
var imageRatioType = {
  FIXED: 'FIXED',
  RESPONSIVE: 'RESPONSIVE'
};
var dimensions = {
  SIDE_BAR_WIDTH: 430,
  STORE_SIDE_BAR_WIDTH: 560,
  MOBILE_PADDING: 120,
  NO_PADDING: 0
};
var mobileSwipeAnimations = {
  EXPAND: 'EXPAND',
  FADE: 'FADE',
  CAROUSEL: 'CAROUSEL',
  CROSSFADE: 'CROSSFADE'
};
var expandAnimations = {
  NO_EFFECT: 'NO_EFFECT',
  EXPAND: 'EXPAND',
  FADE_IN: 'FADE_IN',
  ZOOM: 'ZOOM'
};
var slideTransitions = {
  LINEAR: 'linear',
  EASE_1: 'cubic-bezier(0.46,0.1,0.25,1)',
  EASE_2: 'cubic-bezier(0.86,0,0.5,1)',
  EASE_3: 'cubic-bezier(0.27,0.88,0.58,1.08)'
};
var dataSavedState = {
  SAVED: 'SAVED',
  PUBLISHED: 'PUBLISHED'
};
export var SENTRY_DSN = 'https://53ff996300e94e7983242fc53bc63c5f@sentry.io/1360936';
export default {
  imageRatioType: imageRatioType,
  dimensions: dimensions,
  mobileSwipeAnimations: mobileSwipeAnimations,
  expandAnimations: expandAnimations,
  expandInfoPositions: expandInfoPositions,
  dataSavedState: dataSavedState,
  slideTransitions: slideTransitions
};